import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './TodaysExercise.module.scss';
import MetaSpaceIcon from '../../components/assetComponents/MetaSpaceIcon';
import { ReactComponent as CheckIcon } from '../../assets/common/icons/check.svg';
import { ReactComponent as Refresh } from '../../assets/common/icons/ic_24_refresh.svg';
import { ReactComponent as Left } from '../../assets/common/icons/ic_move_left.svg';
import { ReactComponent as Right } from '../../assets/common/icons/ic_move_right.svg';
import { useEffect, useRef, useState } from 'react';
import DAY from '../../constant/day';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomConfirm from '../../components/CustomConfirm/CustomConfirm';
import Header from '../../components/Header/Header';
import SmartringConnectionModal from '../../components/SmartringConnectionModal/SmartringConnectionModal';
import SmartringConnectingModal from '../../components/SmartringConnectingModal/SmartringConnectingModal';
import SmartringConnectionSuccessModal from '../../components/SmartringConnectionSuccessModal/SmartringConnectionSuccessModal';
import WhirlModal from '../../components/WhirlModal/WhirlModal';
import SmartringConnectionFailModal from '../../components/SmartringConnectionFailModal/SmartringConnectionFailModal';
import HorizontalModal from '../../components/HorizontalModal/HorizontalModal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import Loading from '../../components/Loading/Loading';
import useUserData from '../../hooks/useUserData';
import dateFormatter from '../../common/dateFormatter';
import { IDateScheduleType, ITimelineDataType } from '../../type/ITimelineDataType';
import MskCheckResultContent from '../../components/MskCheckResultContent/MskCheckResultContent';
import { ReactComponent as Time } from '../../assets/common/icons/ic_time.svg';
import Checkbox from '../../components/CheckBox/Checkbox';
import durationFormatter from '../../common/durationFormatter';
import MetabolismCheckResultContent from '../../components/MetabolismCheckResultContent/MetabolismCheckResultContent';

const testData = {
  weeklyTimeline: {
    '2024-08-26': {
      needToStartExam: null,
      userStartedExam: {
        mskGestureExamResult: {
          userId: 45,
          userName: '이주연_소프트보울',
          currentExamDateTime: '2024-08-26T17:15:14',
          examTypeCode: 'M',
          uncomfortablePartCode: 'lb',
          rightOrLeft: 'none',
          targetGestureIndex: 1,
          resultType: 'P3F3',
          examComment: '작은 움직임부터 시작해봐요',
          hasGoalAchieved: false,
          isGoalAdjustmentRequired: false,
          examCompletedPoint: 0,
          examList: [
            {
              gestureCode: 'ev_lbfl',
              gestureName: '몸통 숙이기',
              functionScore: 2,
              painScore: 6,
              movementScore: 34,
              goalMovementScore: 54,
              guideImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/thumbnail/1/ev_lbfl_thumbnail.png',
              goalImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/result_goal/1/ev_lbfl_goal.png',
            },
            {
              gestureCode: 'ev_lbfl',
              gestureName: '몸통 숙이기',
              functionScore: 3,
              painScore: 7,
              movementScore: 30,
              goalMovementScore: 54,
              guideImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/thumbnail/1/ev_lbfl_thumbnail.png',
              goalImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/result_goal/1/ev_lbfl_goal.png',
            },
            {
              gestureCode: 'ev_lbfl',
              gestureName: '몸통 숙이기',
              functionScore: 3,
              painScore: 8,
              movementScore: 23,
              goalMovementScore: 54,
              guideImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/thumbnail/1/ev_lbfl_thumbnail.png',
              goalImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/result_goal/1/ev_lbfl_goal.png',
            },
            {
              gestureCode: 'ev_lbfl',
              gestureName: '몸통 숙이기',
              functionScore: 2,
              painScore: 6,
              movementScore: 34,
              goalMovementScore: 54,
              guideImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/thumbnail/1/ev_lbfl_thumbnail.png',
              goalImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/result_goal/1/ev_lbfl_goal.png',
            },
            {
              gestureCode: 'ev_lbfl',
              gestureName: '몸통 숙이기',
              functionScore: 7,
              painScore: 0,
              movementScore: 91,
              goalMovementScore: 54,
              guideImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/thumbnail/1/ev_lbfl_thumbnail.png',
              goalImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/result_goal/1/ev_lbfl_goal.png',
            },
            {
              gestureCode: 'ev_lbfl',
              gestureName: '몸통 숙이기',
              functionScore: 4,
              painScore: 7,
              movementScore: 33,
              goalMovementScore: 54,
              guideImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/thumbnail/1/ev_lbfl_thumbnail.png',
              goalImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/result_goal/1/ev_lbfl_goal.png',
            },
            {
              gestureCode: 'ev_lbfl',
              gestureName: '몸통 숙이기',
              functionScore: 3,
              painScore: 6,
              movementScore: 37,
              goalMovementScore: 54,
              guideImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/thumbnail/1/ev_lbfl_thumbnail.png',
              goalImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/result_goal/1/ev_lbfl_goal.png',
            },
            {
              gestureCode: 'ev_lbfl',
              gestureName: '몸통 숙이기',
              functionScore: 2,
              painScore: 8,
              movementScore: 20,
              goalMovementScore: 54,
              guideImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/thumbnail/1/ev_lbfl_thumbnail.png',
              goalImage: 'https://dev-storage.togather.co.kr/images/evaluation/lb/result_goal/1/ev_lbfl_goal.png',
            },
          ],
          scoreList: [
            {
              examResultHistoryId: 159,
              examTypeCode: 'R',
              examDateTime: '2024-08-13T16:29:40',
              functionScore: 2,
              painScore: 6,
              movementScore: 34,
              goalMovementScore: 54,
            },
            {
              examResultHistoryId: 164,
              examTypeCode: 'M',
              examDateTime: '2024-08-14T17:27:49',
              functionScore: 8,
              painScore: 3,
              movementScore: 73,
              goalMovementScore: 81,
            },
            {
              examResultHistoryId: 163,
              examTypeCode: 'M',
              examDateTime: '2024-08-26T17:15:14',
              functionScore: 2,
              painScore: 8,
              movementScore: 20,
              goalMovementScore: 54,
            },
          ],
          exerciseStampList: ['2024-08-22', '2024-08-23', '2024-08-24', '2024-08-25'],
        },
        mtaExamResultByExamType: null,
      },
      exerciseStarted: null,
    },
    '2024-08-27': {
      needToStartExam: null,
      userStartedExam: null,
      exerciseStarted: {
        sessionId: 227,
        sessionCode: 'ss_lb_001',
        isCompleted: 'N',
        completedDateTime: null,
        unitList: [
          {
            sessionCode: 'ss_lb_001',
            order: 1,
            unitId: 1509,
            unitCode: 'mctw_10_02',
            unitNameKorean: '엎드려 허리 펴기',
            exerciseTypeCode: 'mctw',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 30,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/mctw_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/mctw_10.mp4',
            description:
              '바닥에 엎드린 자세에서 팔꿈치는 어깨 밑에 둡니다. \n팔꿈치로 지지하고 머리를 들어 앞을 봅니다.\n❗ 통증이 없는 범위까지 움직이세요.',
            isCompleted: 'Y',
          },
          {
            sessionCode: 'ss_lb_001',
            order: 2,
            unitId: 1510,
            unitCode: 'glfb_10_02',
            unitNameKorean: '엉덩이 스트레칭',
            exerciseTypeCode: 'glfb',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 35,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/glfb_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/glfb_10.mp4',
            description:
              '바닥에 누워 양쪽 무릎을 구부립니다. \n양 손으로 무릎을 가슴쪽으로 당깁니다.\n10초 동안 유지한 후 제자리로 돌아옵니다. \n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'Y',
          },
          {
            sessionCode: 'ss_lb_001',
            order: 3,
            unitId: 1511,
            unitCode: 'drwn_03_02',
            unitNameKorean: '허리 안정화',
            exerciseTypeCode: 'drwn',
            repsKind: 'H',
            repetitionCount: 3,
            durationCount: 2,
            videoPlayTime: 100,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/drwn_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/drwn_03.mp4',
            description:
              '바닥에 누워 양쪽 무릎을 구부립니다.\n한 손은 가슴에, 다른 손은 배 위에 올려놓습니다. \n허리는 바닥에 붙인 상태를 유지하면서, 숨을 크게 들이마시고 내쉬면서 배꼽을 바닥으로 당기듯이 배를 쏙 넣어줍니다.\n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_001',
            order: 4,
            unitId: 1512,
            unitCode: 'dwhr_10_02',
            unitNameKorean: '허리 안정화   (오른쪽)',
            exerciseTypeCode: 'dwhr',
            repsKind: 'R',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 95,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/dwhr_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/dwhr_10.mp4',
            description:
              '바닥에 누워 양 손은 옆에 두고 무릎은 구부리고 허리는 바닥에 붙입니다.\n오른쪽 뒤꿈치를 바닥에 붙인 채로 오른쪽 다리를 구부렸다가 폅니다.\n❗ 움직이는 동안 허리가 뜨지 않도록 주의하세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_001',
            order: 5,
            unitId: 1513,
            unitCode: 'dwhl_10_02',
            unitNameKorean: '허리 안정화   (왼쪽)',
            exerciseTypeCode: 'dwhl',
            repsKind: 'R',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 95,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/dwhl_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/dwhl_10.mp4',
            description:
              '바닥에 누워 양 손은 옆에 두고 무릎은 구부리고 허리는 바닥에 붙입니다.\n왼쪽 뒤꿈치를 바닥에 붙인 채로 왼쪽 다리를 구부렸다가 폅니다.\n❗ 움직이는 동안 허리가 뜨지 않도록 주의하세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_001',
            order: 6,
            unitId: 1514,
            unitCode: 'hsfr_10_02',
            unitNameKorean: '허벅지 스트레칭 (오른쪽)',
            exerciseTypeCode: 'hsfr',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 40,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/hsfr_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/hsfr_10.mp4',
            description:
              '누운 자세에서 오른쪽 다리를 들어 양손으로 오른쪽 무릎 뒤쪽을 잡습니다. \n오른쪽 발목을 당기고 다리를 쭉 폅니다. \n10초 동안 유지한 후 제자리로 돌아옵니다. \n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_001',
            order: 7,
            unitId: 1515,
            unitCode: 'hsfl_10_02',
            unitNameKorean: '허벅지 스트레칭 (왼쪽)',
            exerciseTypeCode: 'hsfl',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 40,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/hsfl_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/hsfl_10.mp4',
            description:
              '누운 자세에서 왼쪽 다리를 들어 양손으로 왼쪽 무릎 뒤쪽을 잡습니다. \n왼쪽 발목을 당기고 다리를 쭉 폅니다. \n10초 동안 유지한 후 제자리로 돌아옵니다. \n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'N',
          },
        ],
      },
    },
    '2024-08-28': {
      needToStartExam: null,
      userStartedExam: null,
      exerciseStarted: {
        sessionId: 228,
        sessionCode: 'ss_rr_000',
        isCompleted: 'N',
        completedDateTime: null,
        unitList: [
          {
            sessionCode: 'ss_rr_000',
            order: 1,
            unitId: null,
            unitCode: null,
            unitNameKorean: null,
            exerciseTypeCode: null,
            repsKind: null,
            repetitionCount: null,
            durationCount: null,
            videoPlayTime: null,
            guideImageUrl: null,
            guideVideoUrl: null,
            description: null,
            isCompleted: 'N',
          },
        ],
      },
    },
    '2024-08-29': {
      needToStartExam: null,
      userStartedExam: null,
      exerciseStarted: {
        sessionId: 229,
        sessionCode: 'ss_lb_002',
        isCompleted: 'N',
        completedDateTime: null,
        unitList: [
          {
            sessionCode: 'ss_lb_002',
            order: 1,
            unitId: 1517,
            unitCode: 'hsfr_10_02',
            unitNameKorean: '허벅지 스트레칭 (오른쪽)',
            exerciseTypeCode: 'hsfr',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 40,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/hsfr_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/hsfr_10.mp4',
            description:
              '누운 자세에서 오른쪽 다리를 들어 양손으로 오른쪽 무릎 뒤쪽을 잡습니다. \n오른쪽 발목을 당기고 다리를 쭉 폅니다. \n10초 동안 유지한 후 제자리로 돌아옵니다. \n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_002',
            order: 2,
            unitId: 1518,
            unitCode: 'hsfl_10_02',
            unitNameKorean: '허벅지 스트레칭 (왼쪽)',
            exerciseTypeCode: 'hsfl',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 40,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/hsfl_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/hsfl_10.mp4',
            description:
              '누운 자세에서 왼쪽 다리를 들어 양손으로 왼쪽 무릎 뒤쪽을 잡습니다. \n왼쪽 발목을 당기고 다리를 쭉 폅니다. \n10초 동안 유지한 후 제자리로 돌아옵니다. \n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_002',
            order: 3,
            unitId: 1519,
            unitCode: 'drwn_03_02',
            unitNameKorean: '허리 안정화',
            exerciseTypeCode: 'drwn',
            repsKind: 'H',
            repetitionCount: 3,
            durationCount: 2,
            videoPlayTime: 100,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/drwn_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/drwn_03.mp4',
            description:
              '바닥에 누워 양쪽 무릎을 구부립니다.\n한 손은 가슴에, 다른 손은 배 위에 올려놓습니다. \n허리는 바닥에 붙인 상태를 유지하면서, 숨을 크게 들이마시고 내쉬면서 배꼽을 바닥으로 당기듯이 배를 쏙 넣어줍니다.\n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_002',
            order: 4,
            unitId: 1520,
            unitCode: 'dwhr_10_02',
            unitNameKorean: '허리 안정화   (오른쪽)',
            exerciseTypeCode: 'dwhr',
            repsKind: 'R',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 95,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/dwhr_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/dwhr_10.mp4',
            description:
              '바닥에 누워 양 손은 옆에 두고 무릎은 구부리고 허리는 바닥에 붙입니다.\n오른쪽 뒤꿈치를 바닥에 붙인 채로 오른쪽 다리를 구부렸다가 폅니다.\n❗ 움직이는 동안 허리가 뜨지 않도록 주의하세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_002',
            order: 5,
            unitId: 1521,
            unitCode: 'dwhl_10_02',
            unitNameKorean: '허리 안정화   (왼쪽)',
            exerciseTypeCode: 'dwhl',
            repsKind: 'R',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 95,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/dwhl_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/dwhl_10.mp4',
            description:
              '바닥에 누워 양 손은 옆에 두고 무릎은 구부리고 허리는 바닥에 붙입니다.\n왼쪽 뒤꿈치를 바닥에 붙인 채로 왼쪽 다리를 구부렸다가 폅니다.\n❗ 움직이는 동안 허리가 뜨지 않도록 주의하세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_002',
            order: 6,
            unitId: 1522,
            unitCode: 'glfb_10_02',
            unitNameKorean: '엉덩이 스트레칭',
            exerciseTypeCode: 'glfb',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 35,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/glfb_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/glfb_10.mp4',
            description:
              '바닥에 누워 양쪽 무릎을 구부립니다. \n양 손으로 무릎을 가슴쪽으로 당깁니다.\n10초 동안 유지한 후 제자리로 돌아옵니다. \n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_002',
            order: 7,
            unitId: 1523,
            unitCode: 'mctw_10_02',
            unitNameKorean: '엎드려 허리 펴기',
            exerciseTypeCode: 'mctw',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 30,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/mctw_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/mctw_10.mp4',
            description:
              '바닥에 엎드린 자세에서 팔꿈치는 어깨 밑에 둡니다. \n팔꿈치로 지지하고 머리를 들어 앞을 봅니다.\n❗ 통증이 없는 범위까지 움직이세요.',
            isCompleted: 'N',
          },
        ],
      },
    },
    '2024-08-30': {
      needToStartExam: null,
      userStartedExam: null,
      exerciseStarted: {
        sessionId: 230,
        sessionCode: 'ss_rr_000',
        isCompleted: 'N',
        completedDateTime: null,
        unitList: [
          {
            sessionCode: 'ss_rr_000',
            order: 1,
            unitId: null,
            unitCode: null,
            unitNameKorean: null,
            exerciseTypeCode: null,
            repsKind: null,
            repetitionCount: null,
            durationCount: null,
            videoPlayTime: null,
            guideImageUrl: null,
            guideVideoUrl: null,
            description: null,
            isCompleted: 'N',
          },
        ],
      },
    },
    '2024-08-31': {
      needToStartExam: null,
      userStartedExam: null,
      exerciseStarted: {
        sessionId: 231,
        sessionCode: 'ss_rr_000',
        isCompleted: 'N',
        completedDateTime: null,
        unitList: [
          {
            sessionCode: 'ss_lb_003',
            order: 1,
            unitId: 1525,
            unitCode: 'mcth_10_02',
            unitNameKorean: '엎드려 허리 펴기',
            exerciseTypeCode: 'mcth',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 35,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/mcth_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/mcth_10.mp4',
            description:
              '바닥에 엎드린 자세에서 손을 어깨 밑에 둡니다. \n손바닥으로 바닥을 밀면서 팔꿈치를 쭉 폅니다. \n머리를 들어 앞을 봅니다.\n❗ 통증이 없는 범위까지 움직이세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_003',
            order: 2,
            unitId: 1526,
            unitCode: 'glfb_10_02',
            unitNameKorean: '엉덩이 스트레칭',
            exerciseTypeCode: 'glfb',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 35,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/glfb_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/glfb_10.mp4',
            description:
              '바닥에 누워 양쪽 무릎을 구부립니다. \n양 손으로 무릎을 가슴쪽으로 당깁니다.\n10초 동안 유지한 후 제자리로 돌아옵니다. \n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_003',
            order: 3,
            unitId: 1527,
            unitCode: 'drwn_03_02',
            unitNameKorean: '허리 안정화',
            exerciseTypeCode: 'drwn',
            repsKind: 'H',
            repetitionCount: 3,
            durationCount: 2,
            videoPlayTime: 100,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/drwn_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/drwn_03.mp4',
            description:
              '바닥에 누워 양쪽 무릎을 구부립니다.\n한 손은 가슴에, 다른 손은 배 위에 올려놓습니다. \n허리는 바닥에 붙인 상태를 유지하면서, 숨을 크게 들이마시고 내쉬면서 배꼽을 바닥으로 당기듯이 배를 쏙 넣어줍니다.\n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_003',
            order: 4,
            unitId: 1528,
            unitCode: 'slrr_10_02',
            unitNameKorean: '누워서 한 다리 들기   (오른쪽)',
            exerciseTypeCode: 'slrr',
            repsKind: 'R',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 55,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/slrr_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/slrr_10.mp4',
            description:
              '바닥에 누워 양 손은 옆에 두고 왼쪽 무릎을 구부리고 허리는 바닥에 붙입니다. \n오른쪽 다리를 쭉 펴고 들었다가 내립니다.\n❗ 움직이는 동안 허리가 뜨지 않도록 주의하세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_003',
            order: 5,
            unitId: 1529,
            unitCode: 'slrl_10_02',
            unitNameKorean: '누워서 한 다리 들기   (왼쪽)',
            exerciseTypeCode: 'slrl',
            repsKind: 'R',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 55,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/slrl_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/slrl_10.mp4',
            description:
              '바닥에 누워 양 손은 옆에 두고 오른쪽 무릎을 구부리고 허리는 바닥에 붙입니다. \n왼쪽 다리를 쭉 펴고 들었다가 내립니다.\n❗ 움직이는 동안 허리가 뜨지 않도록 주의하세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_003',
            order: 6,
            unitId: 1530,
            unitCode: 'hsfr_10_02',
            unitNameKorean: '허벅지 스트레칭 (오른쪽)',
            exerciseTypeCode: 'hsfr',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 40,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/hsfr_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/hsfr_10.mp4',
            description:
              '누운 자세에서 오른쪽 다리를 들어 양손으로 오른쪽 무릎 뒤쪽을 잡습니다. \n오른쪽 발목을 당기고 다리를 쭉 폅니다. \n10초 동안 유지한 후 제자리로 돌아옵니다. \n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'N',
          },
          {
            sessionCode: 'ss_lb_003',
            order: 7,
            unitId: 1531,
            unitCode: 'hsfl_10_02',
            unitNameKorean: '허벅지 스트레칭 (왼쪽)',
            exerciseTypeCode: 'hsfl',
            repsKind: 'H',
            repetitionCount: 10,
            durationCount: 2,
            videoPlayTime: 40,
            guideImageUrl: 'https://dev-storage.togather.co.kr/images/exercise/hsfl_thumbnail.png',
            guideVideoUrl: 'https://dev-storage.togather.co.kr/videos/exercise/hsfl_10.mp4',
            description:
              '누운 자세에서 왼쪽 다리를 들어 양손으로 왼쪽 무릎 뒤쪽을 잡습니다. \n왼쪽 발목을 당기고 다리를 쭉 폅니다. \n10초 동안 유지한 후 제자리로 돌아옵니다. \n❗ 통증이 심하지 않은 범위 내에서 움직이세요.',
            isCompleted: 'N',
          },
        ],
      },
    },
  },
  previousExamDate: '2024-08-14',
  nextExamDate: null,
  needToStartExam: false,
};

const ONE_DAY = 60 * 60 * 24 * 1000;

function TodaysExercise() {
  const navigate = useNavigate();
  const userData = useUserData();
  const queryClient = useQueryClient();

  //헤더 관련
  const [isFirstOrReExam, setIsFirstOrReExam] = useState(true); //오늘 날짜 스케쥴이 첫,재검사 인지 판단
  const [menuOpen, setMenuOpen] = useState(false);
  const [refetchProgramModalOpen, setRefetchProgramModalOpen] = useState(false);
  //헤더 관련

  // date picker 관련
  const [week, setWeek] = useState<number[]>(() => {
    const result = [];
    let first = new Date(new Date().setHours(0, 0, 0, 0));
    while (first.getDay() !== 1) first = new Date(first.getTime() - ONE_DAY);
    for (let i = 0; i < 7; i++) {
      result.push(first.getTime());
      first.setDate(first.getDate() + 1);
    }

    return result;
  });

  useEffect(() => {
    setCalendar({
      year: new Date(week[0]).getFullYear(),
      month: new Date(week[0]).getMonth() + 1,
    });
  }, [week]);
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const [selectedDate, setSelectedDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [selectedCalendarIndex, setSelectedCalendarIndex] = useState(
    week.findIndex((day) => {
      return day === selectedDate;
    })
  );

  const [calendar, setCalendar] = useState<{
    year: number;
    month: number;
  }>();

  function handleToday() {
    const result = [];
    let first = new Date(new Date().setHours(0, 0, 0, 0));
    setSelectedDate(first.getTime());
    while (first.getDay() !== 1) first = new Date(first.getTime() - ONE_DAY);
    for (let i = 0; i < 7; i++) {
      result.push(first.getTime());
      first.setDate(first.getDate() + 1);
    }
    setSelectedCalendarIndex(result.findIndex((day) => day === new Date(new Date().setHours(0, 0, 0, 0)).getTime()));
    setWeek(result);
    // 프로그램 삭제로 인한 오늘날짜 변경시 리페치
    if (result[0] === week[0]) queryClient.invalidateQueries(['exercise-of-the-day', result]);
  }

  function handleSpecificDate(date: string) {
    const result = [];
    setSelectedDate(new Date(date).setHours(0, 0, 0, 0));
    let first = new Date(new Date(date).setHours(0, 0, 0, 0));
    while (first.getDay() !== 1) first = new Date(first.getTime() - ONE_DAY);
    for (let i = 0; i < 7; i++) {
      result.push(first.getTime());
      first.setDate(first.getDate() + 1);
    }
    setSelectedCalendarIndex(
      result.findIndex((day) => day === new Date(new Date(date).setHours(0, 0, 0, 0)).getTime())
    );
    setWeek(result);
    // 프로그램 삭제로 인한 오늘날짜 변경시 리페치
    if (result[0] === week[0]) queryClient.invalidateQueries(['exercise-of-the-day', result]);
  }

  function handleWeek(ctl: 'prev' | 'next') {
    const result = [];
    //이전 버튼은 week[0] - 7일 ~ +7일, 다음 버튼은 week[6] + 1일 ~ + 7일
    let first = new Date(ctl === 'prev' ? week[0] - ONE_DAY * 7 : week[6] + ONE_DAY);
    for (let i = 0; i < 7; i++) {
      result.push(first.getTime());
      first.setDate(first.getDate() + 1);
    }
    setWeek(result);
  }
  // date picker 관련

  const { mutateAsync: smartringDisconnection, isLoading: smartringLoading } = useMutation<{
    result: 'SUCCESS';
  }>(() =>
    axiosAuthInstance
      .post(`/api/fhir/smart-ring/subscribe?userId=${userData.data?.userId}`, {
        sessionId: '',
        connectionSet: 'D',
      })
      .then((res) => res.data)
  );

  const { mutateAsync: deleteHistory, isLoading: deleteHistoryLoading } = useMutation(() =>
    axiosAuthInstance
      .delete(`api/fitness/exercise/recommended-program?userId=${userData.data?.userId}`)
      .then((res) => res.data)
  );

  // 스케쥴 데이터
  const { data, isLoading } = useQuery<ITimelineDataType>(
    ['exercise-of-the-day', week],
    () =>
      axiosAuthInstance
        .get(
          `/api/fitness/exercise/today/timeline?startDate=${(() => {
            const date = new Date(week[0]);
            return `${date.getFullYear()}-${dateFormatter(date.getMonth() + 1)}-${dateFormatter(date.getDate())}`;
          })()}&endDate=${(() => {
            const date = new Date(week[6]);
            return `${date.getFullYear()}-${dateFormatter(date.getMonth() + 1)}-${dateFormatter(date.getDate())}`;
          })()}&userId=${userData.data?.userId}`
        )
        .then((res) => res.data),
    {
      cacheTime: 0,
    }
  );
  const [calendarWeekData, setCalendarWeekData] = useState<(IDateScheduleType | null)[]>([]);
  useEffect(() => {
    if (!data) return;
    const newCalendarWeekData = week.map((day) => {
      const dateObj = new Date(day);
      const [year, month, date] = (() => {
        return [dateObj.getFullYear(), dateObj.getMonth() + 1, dateObj.getDate()];
      })();
      return data.weeklyTimeline[`${year}-${dateFormatter(month)}-${dateFormatter(date)}`] || null;
    });
    setCalendarWeekData(newCalendarWeekData);
  }, [data]);

  const [weekData, setWeekData] = useState<
    {
      [key: string]: IDateScheduleType | null;
    }[]
  >([]);

  useEffect(() => {
    if (!data || !week.includes(selectedDate)) return;
    const newWeekData = week.map((day) => {
      const dateObj = new Date(day);
      const [year, month, date] = (() => {
        return [dateObj.getFullYear(), dateObj.getMonth() + 1, dateObj.getDate()];
      })();
      return {
        [`${year}-${dateFormatter(month)}-${dateFormatter(date)}`]:
          data.weeklyTimeline[`${year}-${dateFormatter(month)}-${dateFormatter(date)}`] || null,
      };
    });
    setWeekData(newWeekData);

    const [todayYear, todayMonth, todayDate] = (() => {
      return [today.getFullYear(), today.getMonth() + 1, today.getDate()];
    })();
    const todayData = data.weeklyTimeline[`${todayYear}-${dateFormatter(todayMonth)}-${dateFormatter(todayDate)}`];
    if (todayData) {
      if (todayData.needToStartExam?.firstExam || todayData.needToStartExam?.reExam) setIsFirstOrReExam(true);
      else setIsFirstOrReExam(false);
    }
  }, [data, selectedDate]);
  // 스케쥴 데이터

  // 검사시 저장된 데이터 제거
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  // 검사시 저장된 데이터 제거

  return (
    <>
      <main className={styles.container}>
        <div className={styles.bg}>
          <Header isNavShow />
          <div className={styles.contentArea}>
            <header className={styles.mobileHeader}>
              <button className={styles.backBtn} onClick={() => navigate('/health/fitness')}>
                <MetaSpaceIcon isLight />
              </button>
              <span className={styles.title}>오늘의 운동</span>
              <div
                className={styles.menuBtnWrap}
                tabIndex={3}
                onBlur={(e) => {
                  if (e.relatedTarget === null) setMenuOpen(false);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setMenuOpen((prev) => !prev);
                }}
              >
                {!isFirstOrReExam && (
                  <button className={`${styles.menuBtn} ${menuOpen ? styles.isOpened : ''}`}>
                    <Refresh />
                  </button>
                )}
                {menuOpen && (
                  <ul className={styles.menuList}>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        setRefetchProgramModalOpen(true);
                        setMenuOpen(false);
                      }}
                    >
                      다시 프로그램 추천 받기
                    </li>
                  </ul>
                )}
              </div>
            </header>
            <div className={styles.menu}>
              <button>오늘의 운동</button>
              {!isFirstOrReExam && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setRefetchProgramModalOpen(true);
                    setMenuOpen(false);
                  }}
                >
                  <Refresh />
                  <span>다시 프로그램 추천 받기</span>
                </button>
              )}
            </div>
            <div className={styles.datePickerBg}>
              <div className={styles.datePicker}>
                <div className={styles.first}>
                  <div className={styles.dateControl}>
                    <button onClick={() => handleWeek('prev')}>
                      <Left />
                    </button>
                    <span>
                      {calendar?.year}.{('0' + calendar?.month).slice(-2)}
                    </span>
                    <button onClick={() => handleWeek('next')}>
                      <Right />
                    </button>
                  </div>
                  <button className={styles.todayBtn} onClick={handleToday}>
                    오늘
                  </button>
                </div>
                <ul className={styles.dateList}>
                  {week.map((day, idx) => {
                    const TIME = new Date(day);
                    const DATE = TIME.getDate();
                    const IS_TODAY = today.getTime() === day;
                    const IS_PAST = day <= today.getTime() ? true : false;
                    const ON_SCHEDULE = !!calendarWeekData[idx];
                    return (
                      <li
                        key={day}
                        className={`${styles.dayWrap} ${IS_TODAY ? styles.today : ''} ${IS_PAST ? styles.past : ''} ${
                          selectedDate === day ? styles.hit : ''
                        }`}
                        onClick={() => {
                          setSelectedDate(day);
                          setSelectedCalendarIndex(idx);
                        }}
                      >
                        <span className={styles.day}>{DAY[idx].ko}</span>
                        <span className={styles.date}>{DATE}</span>
                        <div className={styles.markWrap}>
                          {ON_SCHEDULE && !isLoading && <div className={styles.mark}></div>}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className={styles.contentsWrap}>
              {(() => {
                if (
                  !weekData.length ||
                  (isLoading &&
                    !weekData
                      .map((datum) => new Date(Object.keys(datum)[0]).setHours(0, 0, 0, 0))
                      .includes(selectedDate))
                )
                  return <div className={styles.contents}></div>;
                return (
                  <Content
                    weekData={weekData}
                    selectedCalendarIndex={selectedCalendarIndex}
                    previousExamDate={data?.previousExamDate || ''}
                    nextExamDate={data?.nextExamDate || ''}
                    handleSpecificDate={handleSpecificDate}
                  />
                );
              })()}
            </div>
          </div>
        </div>
      </main>
      {refetchProgramModalOpen && (
        <CustomConfirm
          message={'지금까지 진행한<br />운동 프로그램을 삭제할까요?'}
          falseText='아니요'
          trueText='예, 삭제할게요'
          falseFn={() => setRefetchProgramModalOpen(false)}
          trueFn={async () => {
            setRefetchProgramModalOpen(false);
            const res = await smartringDisconnection();
            if (res.result !== 'SUCCESS') return;
            await deleteHistory();
            handleToday();
          }}
        />
      )}

      {(deleteHistoryLoading || isLoading || smartringLoading) && <Loading />}
    </>
  );
}
export default TodaysExercise;

interface IContentProps {
  weekData: {
    [key: string]: IDateScheduleType | null;
  }[];
  selectedCalendarIndex: number;
  previousExamDate: string;
  nextExamDate: string;
  handleSpecificDate: (arg0: string) => void;
}
function Content({
  weekData,
  selectedCalendarIndex,
  previousExamDate,
  nextExamDate,
  handleSpecificDate,
}: IContentProps) {
  const userData = useUserData();
  const restSession = 'ss_rr_000';
  const navigate = useNavigate();
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const today = new Date(new Date().setHours(0, 0, 0, 0));

  const [dateKey, setDateKey] = useState(Object.keys(weekData[selectedCalendarIndex])[0]);
  const [data, setData] = useState<IDateScheduleType | null>(weekData[selectedCalendarIndex][dateKey]);

  const [tabs, setTabs] = useState<('운동' | '검사')[]>([]);
  const [selectedTab, setSelectedTab] = useState<'운동' | '검사' | ''>('');

  const date = Object.keys(weekData[selectedCalendarIndex])[0];
  const dateTimestamp = new Date(date).setHours(0, 0, 0, 0);
  const type: 'past' | 'today' | 'future' =
    today.getTime() > dateTimestamp ? 'past' : today.getTime() === dateTimestamp ? 'today' : 'future';

  useEffect(() => {
    if (scrollAreaRef.current) scrollAreaRef.current.scrollTo(0, 0);
    const newKey = Object.keys(weekData[selectedCalendarIndex])[0];
    setDateKey(newKey);
    setData(weekData[selectedCalendarIndex][newKey]);

    const newTabs: ('운동' | '검사')[] = [];

    if (
      weekData[selectedCalendarIndex][newKey]?.exerciseStarted &&
      weekData[selectedCalendarIndex][newKey]?.exerciseStarted?.sessionCode !== restSession &&
      type !== 'future'
    )
      newTabs.push('운동');
    if (
      weekData[selectedCalendarIndex][newKey]?.userStartedExam ||
      weekData[selectedCalendarIndex][newKey]?.needToStartExam
    )
      newTabs.push('검사');
    setTabs(newTabs);

    setSelectedTab(newTabs[0] || '');
  }, [weekData, selectedCalendarIndex]);

  // 하단 고정 버튼 영역 관련
  const [smartRingCheck, setSmartRingCheck] = useState(true);
  const [continueExerciseModalOpen, setContinueExerciseModalOpen] = useState(false);
  const [smartringConnectionModalOpen, setSmartringConnectionModalOpen] = useState(false);
  const [smartringConnectingModalOpen, setSmartringConnectingModalOpen] = useState(false);
  const [smartringConnectionSuccessModalOpen, setSmartringConnectionSuccessModalOpen] = useState(false);
  const [smartringConnectionFailModalOpen, setSmartringConnectionFailModalOpen] = useState(false);
  const [WhirlModalOpen, setWhirlModalOpen] = useState(false);
  const [horizontalModalOpen, setHorizontalModalOpen] = useState(false);
  // 하단 고정 버튼 영역 관련

  const exerciseStartUnitIndex = useRef(0);

  const useSmartring = useRef<'Y' | 'N'>('N');
  const { mutateAsync: connectSmartring } = useMutation<{ result: string }, null, number>((sessionId) =>
    axiosAuthInstance
      .post(`/api/fhir/observation/resting?userId=${userData.data?.userId}&sessionId=${sessionId}`)
      .then((res) => res.data)
  );
  async function handleSmartringConnect() {
    return await connectSmartring(data?.exerciseStarted?.sessionId || -1);
  }
  return (
    <>
      <div className={styles.contents} ref={scrollAreaRef}>
        {(type === 'today' || (type === 'past' && data?.exerciseStarted?.sessionCode !== restSession)) &&
          weekData[selectedCalendarIndex] &&
          !!tabs.length &&
          !(tabs.length === 1 && tabs[0] === '검사' && data?.needToStartExam) && (
            <div className={styles.tabsWrap}>
              <ul>
                {(() => {
                  return tabs.map((tabName, idx) => (
                    <li
                      key={tabName}
                      className={tabName === selectedTab ? styles.hit : ''}
                      onClick={() => setSelectedTab(tabName)}
                    >
                      {tabName}
                    </li>
                  ));
                })()}
              </ul>
              {selectedTab === '운동' && (
                <span>
                  운동 진행 : {data?.exerciseStarted?.unitList.filter((unit) => unit.isCompleted === 'Y').length || 0}/
                  {data?.exerciseStarted?.unitList.length}
                </span>
              )}
            </div>
          )}

        {/* 휴식, 일정없음, 운동, 검사결과, 휴식일, 검사 시작,  */}

        {/* 과거 (휴식일, 일정없음, 운동, 검사결과) */}

        {/* 과거 일정 없음 */}
        {type === 'past' && !data && <NoSchedule type={'past'} />}

        {/* 과거 msk 검사 결과 */}
        {type === 'past' &&
          selectedTab === '검사' &&
          !data?.needToStartExam &&
          data?.userStartedExam?.mskGestureExamResult && (
            <>
              {(() => {
                let prevDate = '';
                const weekTodayPrev = weekData.slice(0, selectedCalendarIndex);
                for (let i = weekTodayPrev.length - 1; i >= 0; i--) {
                  const key = Object.keys(weekTodayPrev[i])[0];
                  if (weekTodayPrev[i][key]?.userStartedExam) {
                    prevDate = key;
                    break;
                  }
                }
                if (!prevDate) prevDate = previousExamDate;
                let nextDate = '';
                const weekTodayNext = weekData.slice(selectedCalendarIndex + 1, 7);
                for (let i = 0; i < weekTodayNext.length; i++) {
                  const key = Object.keys(weekTodayNext[i])[0];
                  console.log(weekTodayNext[i][key], key);
                  if (weekTodayNext[i][key]?.userStartedExam) {
                    nextDate = key;
                    break;
                  }
                }
                if (!nextDate) nextDate = nextExamDate;
                return (
                  <>
                    <div className={`${styles.testResultWrap} ${!(prevDate || nextDate) ? styles.noSubmitBtn : ''}`}>
                      <MskCheckResultContent data={data?.userStartedExam?.mskGestureExamResult} />
                    </div>
                    {(prevDate || nextDate) && (
                      <div className={styles.fixedOtherResultBtn}>
                        <div>
                          <button
                            className={`${styles.prevBtn} ${prevDate ? styles.hit : ''}`}
                            onClick={() => {
                              if (prevDate) handleSpecificDate(prevDate);
                            }}
                          >
                            이전 검사결과
                          </button>
                          <button
                            className={`${styles.nextBtn} ${nextDate ? styles.hit : ''}`}
                            onClick={() => {
                              if (nextDate) handleSpecificDate(nextDate);
                            }}
                          >
                            다음 검사결과
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                );
              })()}
            </>
          )}
        {/* 과거 대사 검사 결과 */}
        {type === 'past' &&
          selectedTab === '검사' &&
          data?.userStartedExam?.mtaExamResultByExamType &&
          !data?.needToStartExam && (
            <>
              {(() => {
                let prevDate = '';
                const weekTodayPrev = weekData.slice(0, selectedCalendarIndex);
                for (let i = weekTodayPrev.length - 1; i >= 0; i--) {
                  const key = Object.keys(weekTodayPrev[i])[0];
                  if (weekTodayPrev[i][key]?.userStartedExam) {
                    prevDate = key;
                    break;
                  }
                }
                if (!prevDate) prevDate = previousExamDate;
                let nextDate = '';
                const weekTodayNext = weekData.slice(selectedCalendarIndex + 1, 7);
                for (let i = 0; i < weekTodayNext.length; i++) {
                  const key = Object.keys(weekTodayNext[i])[0];
                  if (weekTodayNext[i][key]?.userStartedExam) {
                    nextDate = key;
                    break;
                  }
                }
                if (!nextDate) nextDate = nextExamDate;
                return (
                  <>
                    <div className={`${styles.testResultWrap} ${!(prevDate || nextDate) ? styles.noSubmitBtn : ''}`}>
                      <MetabolismCheckResultContent data={data?.userStartedExam?.mtaExamResultByExamType} />
                    </div>
                    {(prevDate || nextDate) && (
                      <div className={styles.fixedOtherResultBtn}>
                        <div>
                          <button
                            className={`${styles.prevBtn} ${prevDate ? styles.hit : ''}`}
                            onClick={() => {
                              if (prevDate) handleSpecificDate(prevDate);
                            }}
                          >
                            이전 검사결과
                          </button>
                          <button
                            className={`${styles.nextBtn} ${nextDate ? styles.hit : ''}`}
                            onClick={() => {
                              if (nextDate) handleSpecificDate(nextDate);
                            }}
                          >
                            다음 검사결과
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                );
              })()}
            </>
          )}
        {/*  */}

        {/* 과거 운동 기록 */}
        {type === 'past' &&
          selectedTab === '운동' &&
          data?.exerciseStarted &&
          (() => {
            const allUnitCount = data.exerciseStarted.unitList.length;
            const completedUnitCount = data.exerciseStarted.unitList.filter((unit) => unit.isCompleted === 'Y').length;
            const duringExercise = !!completedUnitCount;
            return (
              <>
                <div className={styles.exerciseContainer}>
                  <ul className={styles.exerciseList}>
                    {data.exerciseStarted.unitList.map((unit, idx) => {
                      const totalTime = durationFormatter(unit.videoPlayTime * unit.durationCount);
                      return (
                        <li
                          key={idx}
                          className={`${styles.exerciseWrap} ${unit.isCompleted === 'Y' ? styles.complete : ''}`}
                        >
                          <div className={styles.exerciseThumbnail}>
                            <img src={unit.guideImageUrl} alt={unit.unitNameKorean} />
                          </div>
                          <div className={styles.exerciseDetailWrap}>
                            <div className={styles.exerciseInfo}>
                              <div className={styles.time}>
                                <Time />
                                <span>총 {totalTime}</span>
                              </div>
                              <span className={styles.set}>
                                ({unit.repetitionCount}
                                {unit.repsKind === 'H' ? '초' : '회'} x {unit.durationCount}세트)
                              </span>
                            </div>
                            <h3 className={styles.exerciseTitle}>{unit.unitNameKorean}</h3>
                          </div>
                          {unit.isCompleted === 'Y' && (
                            <div className={styles.completeMarkWrap}>
                              {/* <Check /> */}
                              <CheckIcon />
                              <span>완료</span>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className={styles.fixedSubmitWrap}>
                  <label className={styles.checkboxWrap}>
                    {!duringExercise && (
                      <Checkbox isChecked={smartRingCheck} onClick={() => setSmartRingCheck((prev) => !prev)} />
                    )}
                    <span>
                      {!duringExercise ? '스마트링 활용하기' : '이어서 운동하면 스마트링을 활용할 수 없어요.'}
                    </span>
                  </label>
                  <div
                    className={styles.btn}
                    onClick={() => {
                      if (allUnitCount === completedUnitCount) setWhirlModalOpen(true);
                      else if (duringExercise) setContinueExerciseModalOpen(true);
                      else if (smartRingCheck) setSmartringConnectionModalOpen(true);
                      else setWhirlModalOpen(true);
                    }}
                  >
                    <CustomButton height={56}>
                      {(() => {
                        return allUnitCount === completedUnitCount ? '운동 다시하기' : '운동 진행하기';
                      })()}
                    </CustomButton>
                  </div>
                </div>
              </>
            );
          })()}
        {/*  */}

        {/* 과거 휴식일 */}
        {type === 'past' && data?.exerciseStarted?.sessionCode === restSession && (
          <InSchedule type='past' type2='rest' />
        )}
        {/* 과거 */}

        {/* 오늘 ( 휴식일, 운동, 검사 진행(첫/재/중간), 검사결과 ) */}

        {/* 오늘 msk 검사 결과 */}
        {type === 'today' &&
          selectedTab === '검사' &&
          !data?.needToStartExam &&
          data?.userStartedExam?.mskGestureExamResult && (
            <>
              {(() => {
                let prevDate = '';
                const weekTodayPrev = weekData.slice(0, selectedCalendarIndex);
                for (let i = weekTodayPrev.length - 1; i >= 0; i--) {
                  const key = Object.keys(weekTodayPrev[i])[0];
                  if (weekTodayPrev[i][key]?.userStartedExam) {
                    prevDate = key;
                    break;
                  }
                }
                if (!prevDate) prevDate = previousExamDate;
                let nextDate = '';
                const weekTodayNext = weekData.slice(selectedCalendarIndex + 1, 7);
                for (let i = 0; i < weekTodayNext.length; i++) {
                  const key = Object.keys(weekTodayNext[i])[0];
                  if (weekTodayNext[i][key]?.userStartedExam) {
                    nextDate = key;
                    break;
                  }
                }
                if (!nextDate) nextDate = nextExamDate;
                return (
                  <>
                    <div className={`${styles.testResultWrap} ${!(prevDate || nextDate) ? styles.noSubmitBtn : ''}`}>
                      <MskCheckResultContent data={data?.userStartedExam?.mskGestureExamResult} />
                    </div>
                    {(prevDate || nextDate) && (
                      <div className={styles.fixedOtherResultBtn}>
                        <div>
                          <button
                            className={`${styles.prevBtn} ${prevDate ? styles.hit : ''}`}
                            onClick={() => {
                              if (prevDate) handleSpecificDate(prevDate);
                            }}
                          >
                            이전 검사결과
                          </button>
                          <button
                            className={`${styles.nextBtn} ${nextDate ? styles.hit : ''}`}
                            onClick={() => {
                              if (nextDate) handleSpecificDate(nextDate);
                            }}
                          >
                            다음 검사결과
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                );
              })()}
            </>
          )}

        {/* 오늘 mta 검사 결과 */}
        {type === 'today' &&
          selectedTab === '검사' &&
          data?.userStartedExam?.mtaExamResultByExamType &&
          !data?.needToStartExam && (
            <>
              {(() => {
                let prevDate = '';
                const weekTodayPrev = weekData.slice(0, selectedCalendarIndex);
                for (let i = weekTodayPrev.length - 1; i >= 0; i--) {
                  const key = Object.keys(weekTodayPrev[i])[0];
                  if (weekTodayPrev[i][key]?.userStartedExam) {
                    prevDate = key;
                    break;
                  }
                }
                if (!prevDate) prevDate = previousExamDate;
                let nextDate = '';
                const weekTodayNext = weekData.slice(selectedCalendarIndex + 1, 7);
                for (let i = 0; i < weekTodayNext.length; i++) {
                  const key = Object.keys(weekTodayNext[i])[0];
                  if (weekTodayNext[i][key]?.userStartedExam) {
                    nextDate = key;
                    break;
                  }
                }
                if (!nextDate) nextDate = nextExamDate;
                return (
                  <>
                    <div className={`${styles.testResultWrap} ${!(prevDate || nextDate) ? styles.noSubmitBtn : ''}`}>
                      <MetabolismCheckResultContent data={data?.userStartedExam?.mtaExamResultByExamType} />
                    </div>
                    {(prevDate || nextDate) && (
                      <div className={styles.fixedOtherResultBtn}>
                        <div>
                          <button
                            className={`${styles.prevBtn} ${prevDate ? styles.hit : ''}`}
                            onClick={() => {
                              if (prevDate) handleSpecificDate(prevDate);
                            }}
                          >
                            이전 검사결과
                          </button>
                          <button
                            className={`${styles.nextBtn} ${nextDate ? styles.hit : ''}`}
                            onClick={() => {
                              if (nextDate) handleSpecificDate(nextDate);
                            }}
                          >
                            다음 검사결과
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                );
              })()}
            </>
          )}

        {/* 오늘 msk, mta 검사 시작 */}
        {type === 'today' && selectedTab === '검사' && data?.needToStartExam && (
          <TodayExam examType={data.needToStartExam.firstExam ? 'first' : data.needToStartExam.reExam ? 're' : 'mid'} />
        )}

        {/* 오늘 운동 */}
        {(type === 'today' || type === 'future') &&
          selectedTab === '운동' &&
          data?.exerciseStarted &&
          (() => {
            const allUnitCount = data.exerciseStarted.unitList.length;
            const completedUnitCount = data.exerciseStarted.unitList.filter((unit) => unit.isCompleted === 'Y').length;
            const duringExercise = !!completedUnitCount;
            return (
              <>
                <div className={styles.exerciseContainer}>
                  <ul className={styles.exerciseList}>
                    {data.exerciseStarted.unitList.map((unit, idx) => {
                      const totalTime = durationFormatter(unit.videoPlayTime * unit.durationCount);
                      return (
                        <li
                          key={idx}
                          className={`${styles.exerciseWrap} ${unit.isCompleted === 'Y' ? styles.complete : ''}`}
                        >
                          <div className={styles.exerciseThumbnail}>
                            <img src={unit.guideImageUrl} alt={unit.unitNameKorean} />
                          </div>
                          <div className={styles.exerciseDetailWrap}>
                            <div className={styles.exerciseInfo}>
                              <div className={styles.time}>
                                <Time />
                                <span>총 {totalTime}</span>
                              </div>
                              <span className={styles.set}>
                                ({unit.repetitionCount}
                                {unit.repsKind === 'H' ? '초' : '회'} x {unit.durationCount}세트)
                              </span>
                            </div>
                            <h3 className={styles.exerciseTitle}>{unit.unitNameKorean}</h3>
                          </div>
                          {unit.isCompleted === 'Y' && (
                            <div className={styles.completeMarkWrap}>
                              <CheckIcon />
                              <span>완료</span>
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className={styles.fixedSubmitWrap}>
                  <label className={styles.checkboxWrap}>
                    {!duringExercise && (
                      <Checkbox isChecked={smartRingCheck} onClick={() => setSmartRingCheck((prev) => !prev)} />
                    )}
                    <span>
                      {!duringExercise ? '스마트링 활용하기' : '이어서 운동하면 스마트링을 활용할 수 없어요.'}
                    </span>
                  </label>
                  <div
                    className={styles.btn}
                    onClick={() => {
                      if (allUnitCount === completedUnitCount) setWhirlModalOpen(true);
                      else if (duringExercise) setContinueExerciseModalOpen(true);
                      else if (smartRingCheck) setSmartringConnectionModalOpen(true);
                      else setWhirlModalOpen(true);
                    }}
                  >
                    <CustomButton height={56}>
                      {(() => {
                        return allUnitCount === completedUnitCount ? '운동 다시하기' : '운동 진행하기';
                      })()}
                    </CustomButton>
                  </div>
                </div>
              </>
            );
          })()}

        {/* 오늘 휴식일 */}
        {type === 'today' && data?.exerciseStarted?.sessionCode === restSession && (
          <InSchedule type='past' type2='rest' />
        )}
        {/* 오늘 */}

        {/* 미래(일정없음, 휴식일, 운동 예정일)  */}
        {/* 일정없음 */}
        {type === 'future' && !data && <NoSchedule type='future' />}

        {/* 미래 휴식일, 예정일 */}
        {type === 'future' && data && (
          <InSchedule type='future' type2={data?.exerciseStarted?.sessionCode === restSession ? 'rest' : 'exercise'} />
        )}
        {/* 미래 */}
      </div>
      {continueExerciseModalOpen && (
        <CustomConfirm
          message={'이어서 운동을 진행할까요?'}
          falseText='처음부터'
          trueText='중간부터 이어서'
          falseFn={() => {
            exerciseStartUnitIndex.current = 0;
            setContinueExerciseModalOpen(false);
            setWhirlModalOpen(true);
          }}
          trueFn={() => {
            exerciseStartUnitIndex.current =
              data?.exerciseStarted?.unitList.findIndex((unit) => unit.isCompleted === 'N') || 0;
            setContinueExerciseModalOpen(false);
            if (!!data?.exerciseStarted?.unitList.filter((unit) => unit.isCompleted === 'Y').length)
              setWhirlModalOpen(true);
            else setSmartringConnectionModalOpen(true);
          }}
        />
      )}
      {smartringConnectionModalOpen && (
        <SmartringConnectionModal
          trueFn={() => {
            setSmartringConnectionModalOpen(false);
            setSmartringConnectingModalOpen(true);
          }}
          falseFn={() => {
            setSmartringConnectionModalOpen(false);
            setWhirlModalOpen(true);
          }}
        />
      )}
      {smartringConnectingModalOpen && (
        <SmartringConnectingModal
          connect={() => {
            return handleSmartringConnect();
          }}
          onSuccess={() => {
            setSmartringConnectingModalOpen(false);
            setSmartringConnectionSuccessModalOpen(true);
            useSmartring.current = 'Y';
          }}
          onFail={() => {
            setSmartringConnectingModalOpen(false);
            setSmartringConnectionFailModalOpen(true);
            useSmartring.current = 'N';
          }}
          onCancel={() => {
            setSmartringConnectingModalOpen(false);
          }}
        />
      )}
      {smartringConnectionSuccessModalOpen && (
        <SmartringConnectionSuccessModal
          onSubmit={() => {
            setSmartringConnectionSuccessModalOpen(false);
            setWhirlModalOpen(true);
          }}
        />
      )}
      {smartringConnectionFailModalOpen && (
        <SmartringConnectionFailModal
          onCancel={() => {
            setSmartringConnectionFailModalOpen(false);
            setWhirlModalOpen(true);
          }}
          onSubmit={() => {
            setSmartringConnectionFailModalOpen(false);
            setSmartringConnectingModalOpen(true);
            // setSmartringConnectingModalOpen(true);
          }}
        />
      )}
      {WhirlModalOpen && (
        <WhirlModal
          onSubmit={() => {
            setWhirlModalOpen(false);
            if (window.innerWidth >= 1024)
              navigate(
                `/exercise/${exerciseStartUnitIndex.current === -1 ? 0 : exerciseStartUnitIndex.current}/guide/${data
                  ?.exerciseStarted?.sessionId}`,
                {
                  state: {
                    unitList: data?.exerciseStarted?.unitList,
                    useSmartring: useSmartring.current,
                  },
                }
              );
            else setHorizontalModalOpen(true);
          }}
        />
      )}
      {horizontalModalOpen && (
        <HorizontalModal
          onSubmit={() => {
            navigate(
              `/exercise/${exerciseStartUnitIndex.current === -1 ? 0 : exerciseStartUnitIndex.current}/guide/${data
                ?.exerciseStarted?.sessionId}`,
              {
                state: {
                  unitList: data?.exerciseStarted?.unitList,
                  useSmartring: useSmartring.current,
                },
              }
            );
          }}
        />
      )}
    </>
  );
}

interface ITodayExamProps {
  examType: 'first' | 're' | 'mid';
}
function TodayExam({ examType }: ITodayExamProps) {
  const examTypeObj = {
    first: 'I',
    re: 'R',
    mid: 'M',
  };
  const navigate = useNavigate();
  const userData = useUserData();
  const message =
    examType === 'mid'
      ? '운동 프로그램 추천을 위해<br />중간검사를 진행해주세요'
      : userData.data?.userType === 'MSK'
        ? '불편한 부위를 확인해<br />운동 프로그램을 추천해드려요'
        : '원하는 강도의 운동 프로그램을<br />추천해드려요';
  return (
    <div className={`${styles.todayExamContainer} ${examType === 'mid' ? styles.midExam : ''}`}>
      <div className={styles.todayExamContent}>
        <div>
          <p dangerouslySetInnerHTML={{ __html: message }}></p>
          <div className={styles.banner}></div>
        </div>
      </div>
      <div className={styles.submitButtonWrap}>
        <div
          onClick={() => {
            if (userData.data?.userType === 'MSK') {
              if (examType === 'first') navigate('/physical-check/physical-info/msk');
              else if (examType === 're') navigate('/physical-check/msk');
              else navigate('/physical-check/msk?index=1');
            } else if (userData.data?.userType === 'MTA') {
              if (examType === 'first')
                navigate('/physical-check/physical-info/metabolism', {
                  state: {
                    examType: examTypeObj[examType],
                  },
                });
              else if (examType === 're') {
                navigate('/physical-check/metabolism?index=1', {
                  state: {
                    examType: examTypeObj[examType],
                  },
                });
              } else if (examType === 'mid') {
                navigate('/physical-check/metabolism?index=0', {
                  state: {
                    examType: examTypeObj[examType],
                  },
                });
              }
            }
          }}
        >
          <CustomButton height={56}>동작 검사하기</CustomButton>
        </div>
      </div>
    </div>
  );
}

interface INoScheduleProps {
  type: 'past' | 'future';
}
function NoSchedule({ type }: INoScheduleProps) {
  const messageData = {
    past: '운동하지 않은 날이에요',
    future: '아직 예정된 운동이 없어요',
  };
  const [message] = useState(messageData[type]);
  return (
    <div className={`${styles.noResult} ${styles[type]}`}>
      <div>
        <p>{message}</p>
        <div className={styles.banner}></div>
      </div>
    </div>
  );
}

interface IInSchedule {
  type: 'past' | 'future';
  type2: 'rest' | 'exercise';
}

function InSchedule({ type, type2 }: IInSchedule) {
  console.log(type, type2);
  const titleData = {
    past: {
      rest: '휴식일',
      exercise: '',
    },
    future: {
      rest: '휴식 예정일',
      exercise: '운동 예정일',
    },
  };
  const title = titleData[type][type2];

  const messageData = {
    past: {
      rest: '충분한 휴식은 운동에 큰 도움이 돼요.',
      exercise: '',
    },
    future: {
      rest: '충분한 휴식은 운동에 큰 도움이 돼요.',
      exercise: '순서대로 일정을 진행하면 운동할 수 있어요.',
    },
  };
  const message = messageData[type][type2];
  return (
    <div className={`${styles.inSchedule} ${styles[type2]}`}>
      <div>
        <h3>{title}</h3>
        <p>{message}</p>
        <div className={styles.banner}></div>
      </div>
    </div>
  );
}
