import { useState } from 'react';
import Frame from '../../components/Frame/Frame';
import styles from './ExerciseResult.module.scss';
import Checkbox from '../../components/CheckBox/Checkbox';
import { useNavigate, useParams } from 'react-router-dom';
import MultipleButton from '../../components/MultipleButton/MultipleButton';
import { ReactComponent as Heart } from '../../assets/common/icons/ic_heart.svg';
import { ReactComponent as Exercise } from '../../assets/common/icons/ic_exercise.svg';
import { ReactComponent as Notice } from '../../assets/common/icons/ic_notice.svg';
import CurveChart from '../../components/CurveChart/CurveChart';
import IntensityGuideModal from '../../components/IntensityGuideModal/IntensityGuideModal';
import DonutChart from '../../components/DonutChart/DonutChart';
import { useMutation, useQuery } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import { IHistoryType, IQuantityType } from '../../type/exerciseResult';
import Loading from '../../components/Loading/Loading';
import HeartRateGuideModal from '../../components/HeartRateGuideModal/HeartRateGuideModal';
import axios from 'axios';
import useUserData from '../../hooks/useUserData';
import IExerciseResultType, { Intensity } from '../../type/exerciseResultType';
import { ITimelineDataType } from '../../type/ITimelineDataType';
import dateFormatter from '../../common/dateFormatter';
import durationFormatter from '../../common/durationFormatter';

function ExerciseResult() {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const userData = useUserData();
  const [heartRateGuideModalOpen, setHeartRateGuideModalOpen] = useState(false);
  const [intensityGuideModalOpen, setIntensityGuideModalOpen] = useState(false);
  const [feedCheck, setFeedCheck] = useState(false);
  const [feedMessage, setFeedMessage] = useState('');
  const [movementCheckConfirmOpen, setMovementCheckConfirmOpen] = useState(false);
  async function handleFeedBtn() {
    if (feedCheck) await mutateFeed();
    navigate('/cafeteria/feed');
  }

  const { mutateAsync: mutateFeed, isLoading: mutateFeedLoading } = useMutation(() =>
    axiosAuthInstance.post('/api/cafeteria/feed', {
      userId: userData.data?.userId,
      contents: feedMessage || '오늘의 운동을 완료했어요!',
      feedType: 'E',
      sessionId: sessionId,
    })
  );
  async function handleSubmit() {
    if (feedCheck) await mutateFeed();
    const today = (() => {
      const dateObject = new Date();
      return `${dateObject.getFullYear()}-${dateFormatter(dateObject.getMonth() + 1)}-${dateFormatter(
        dateObject.getDate()
      )}`;
    })();
    if (calendarData?.weeklyTimeline?.[today]?.needToStartExam?.midExam) setMovementCheckConfirmOpen(true);
    else navigate('/fitness/exercise-of-the-day?exercise-complete=true');
  }

  // const { data: history, isLoading: historyLoading } = useQuery<IHistoryType>(['exerciseHistory'], () =>
  //   // axiosAuthInstance
  //   axios.get('https://dev-api.togather.co.kr/api/fitness/exercise/today/history').then((res) => res.data)
  // );
  // const { data: quantity, isLoading: quantityLoading } = useQuery<IQuantityType>(['exerciseQuantity'], () =>
  //   // axiosAuthInstance
  //   axios.get('https://dev-api.togather.co.kr/api/fitness/exercise/today/quantity').then((res) => res.data)
  // );

  const { data: exerciseResult, isLoading } = useQuery<IExerciseResultType>(['exerciseResult', sessionId], () =>
    axiosAuthInstance
      .post(`/api/fhir/observation/exercise?userId=${userData.data?.userId}&sessionId=${sessionId}`)
      .then((res) => res.data)
  );

  // 마지막 세션이었는지, 중간검사 할 차례인지 체크
  const [week, setWeek] = useState<number[]>(() => {
    const result = [];
    let first = new Date(new Date().setHours(0, 0, 0, 0));
    while (first.getDay() !== 1) first = new Date(first.getTime() - 60 * 60 * 24 * 1000);
    for (let i = 0; i < 7; i++) {
      result.push(first.getTime());
      first.setDate(first.getDate() + 1);
    }
    return result;
  });
  const { data: calendarData } = useQuery<ITimelineDataType>(
    ['exercise-of-the-day', week],
    () =>
      axiosAuthInstance
        .get(
          `/api/fitness/exercise/today/timeline?startDate=${(() => {
            const date = new Date(week[0]);
            return `${date.getFullYear()}-${dateFormatter(date.getMonth() + 1)}-${dateFormatter(date.getDate())}`;
          })()}&endDate=${(() => {
            const date = new Date(week[6]);
            return `${date.getFullYear()}-${dateFormatter(date.getMonth() + 1)}-${dateFormatter(date.getDate())}`;
          })()}&userId=${userData.data?.userId}`
        )
        .then((res) => res.data),
    {
      cacheTime: 0,
    }
  );
  // 마지막 세션이었는지, 중간검사 할 차례인지 체크
  return (
    <>
      <Frame menuTitle='오늘의 운동'>
        <header className={styles.mobileHeader}>오늘의 운동 완료</header>
        <div className={styles.container}>
          <div className={`${styles.bannerWrap} ${styles.outer}`}></div>
          <section className={styles.momentumWrap}>
            <h5>오늘의 운동량</h5>
            <div className={styles.momentumDetail}>
              <div className={styles.count}>
                <span>진행한 운동</span>
                <span>{exerciseResult?.completedUnitCount.toLocaleString()}개</span>
              </div>
              <div className={styles.time}>
                <span>운동 소요시간</span>
                <span>
                  {(() => {
                    if (!exerciseResult) return 0;
                    const [hour, minute] = exerciseResult?.exerciseDuration.split(':');
                    return Number(hour) * 60 + Number(minute);
                  })()}
                  분
                </span>
              </div>
            </div>
          </section>
          {/* 스마트링 */}
          {exerciseResult?.xaxis?.endDateTime && exerciseResult?.hrList && (
            <>
              <section className={styles.heartRateWrap}>
                <div className={styles.sectionHeader}>
                  <Heart />
                  <h5>심박수</h5>
                  <button onClick={() => setHeartRateGuideModalOpen(true)}>
                    <Notice />
                  </button>
                </div>
                <div className={styles.curveChartWrap}>
                  <CurveChart
                    data={exerciseResult.hrList.map((datum) => datum.hr)}
                    startTime={exerciseResult.xaxis.startDateTime}
                    endTime={exerciseResult.xaxis.endDateTime}
                    HRRest={exerciseResult.hrRest}
                    MPHR={exerciseResult.mphr}
                  />
                </div>
              </section>
              {exerciseResult?.xaxis.endDateTime &&
                exerciseResult.hrIntensities &&
                exerciseResult?.hrList &&
                (() => {
                  const intensityData = exerciseResult.hrIntensities.sort(
                    (a, b) => a.intensityCriteria - b.intensityCriteria
                  );
                  return (
                    <section className={styles.intensityWrap}>
                      <div className={styles.sectionHeader}>
                        <Exercise />
                        <h5>운동강도</h5>
                        <button onClick={() => setIntensityGuideModalOpen(true)}>
                          <Notice />
                        </button>
                      </div>
                      <div className={styles.donutChartWrap}>
                        <div className={styles.chart}>
                          <DonutChart
                            data={[
                              {
                                order: 1,
                                value: Math.round(intensityData[3].percentage),
                                // value: 1,
                                intensity: '최고강도',
                              },
                              {
                                order: 2,
                                value: Math.round(intensityData[2].percentage),
                                // value: 49,
                                intensity: '고강도',
                              },
                              {
                                order: 3,
                                value: Math.round(intensityData[1].percentage),
                                // value: 25,
                                intensity: '중강도',
                              },
                              {
                                order: 4,
                                value: Math.round(intensityData[0].percentage),
                                // value: 25,
                                intensity: '저강도',
                              },
                            ].filter((datum) => datum.value)}
                          />
                        </div>
                      </div>
                      <ul className={styles.intensityList}>
                        <li>
                          <div className={styles.color}></div>
                          <span className={styles.difficulty}>최고강도 운동시간</span>
                          <span className={styles.percentage}>
                            {durationFormatter(intensityData[3].count)} ({Math.round(intensityData[3].percentage)}
                            %)
                          </span>
                        </li>
                        <li>
                          <div className={styles.color}></div>
                          <span className={styles.difficulty}>고강도 운동시간</span>
                          <span className={styles.percentage}>
                            {durationFormatter(intensityData[2].count)} ({Math.round(intensityData[2].percentage)}
                            %)
                          </span>
                        </li>
                        <li>
                          <div className={styles.color}></div>
                          <span className={styles.difficulty}>중강도 운동시간</span>
                          <span className={styles.percentage}>
                            {durationFormatter(intensityData[1].count)} ({Math.round(intensityData[1].percentage)}
                            %)
                          </span>
                        </li>
                        <li>
                          <div className={styles.color}></div>
                          <span className={styles.difficulty}>저강도 운동시간</span>
                          <span className={styles.percentage}>
                            {durationFormatter(intensityData[0].count)} ({Math.round(intensityData[0].percentage)}
                            %)
                          </span>
                        </li>
                      </ul>
                    </section>
                  );
                })()}
            </>
          )}
          {/* 스마트링 */}
          <section className={styles.feedWrap}>
            <h5>피드 남기기</h5>
            <div className={styles.feedBox}>
              <label>
                <Checkbox isChecked={feedCheck} onClick={() => setFeedCheck((prev) => !prev)} />
                <span>카페테리아 피드 남기기</span>
              </label>
              <textarea
                className={!feedCheck ? styles.lock : ''}
                value={feedMessage}
                disabled={!feedCheck}
                maxLength={40}
                onChange={(e) => {
                  const message = e.currentTarget.value;
                  setFeedMessage(message);
                }}
                onBlur={() => {
                  setFeedMessage((prev) => prev.slice(0, 40));
                }}
                placeholder='오늘의 운동을 완료했어요!'
              ></textarea>
              <span className={styles.textLength}>{feedMessage.length}/40</span>
            </div>
          </section>
          <div className={styles.submitBtnsWrap}>
            <button onClick={handleFeedBtn}>피드 보러가기</button>
            <button onClick={handleSubmit}>완료하기</button>
          </div>
        </div>
      </Frame>
      {movementCheckConfirmOpen && (
        <div className={styles.dimed}>
          <article className={styles.modal}>
            <div className={styles.modalContent}>
              <p>이번 프로그램의 운동을 모두 완료했어요.</p>
              <p>동작검사를 진행할까요?</p>
            </div>
            <div className={styles.modalSubmitBtns}>
              <MultipleButton
                falseText='아니요'
                trueText='예, 진행할게요'
                falseFn={() => navigate('/fitness/exercise-of-the-day?exercise-complete=true')}
                trueFn={() => {
                  if (userData.data?.userType === 'MSK') navigate('/physical-check/msk?index=1');
                  else if (userData.data?.userType === 'MTA')
                    navigate('/physical-check/metabolism?index=0', {
                      state: {
                        examType: 'M',
                      },
                    });
                }}
              />
            </div>
          </article>
        </div>
      )}
      {heartRateGuideModalOpen && <HeartRateGuideModal onSubmit={() => setHeartRateGuideModalOpen(false)} />}
      {intensityGuideModalOpen && <IntensityGuideModal onSubmit={() => setIntensityGuideModalOpen(false)} />}
      {(isLoading || mutateFeedLoading) && <Loading />}
    </>
  );
}
export default ExerciseResult;
