import { useLocation, useNavigate } from 'react-router-dom';
import styles from './PersonalCounseling.module.scss';
import Header from '../../components/Header/Header';
import MetaSpaceIcon from '../../components/assetComponents/MetaSpaceIcon';
import { useEffect, useState } from 'react';
import counselorDefault from '../../assets/common/background/counselor_default.png';
import { ReactComponent as Refresh } from '../../assets/common/icons/ic_refresh.svg';
import Counseling from '../../components/Counseling/Counseling';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { axiosAuthInstance } from '../../api/axios';
import ICounselorListType from '../../type/ICounselorListType';
import useCostomInView from '../../hooks/useCustomInView';
import Loading from '../../components/Loading/Loading';
import { ReactComponent as Open } from '../../assets/common/icons/ic_menu_open.svg';
import LockerRoomNavigateModal from '../../components/LockerRoomNavigateModal/LockerRoomNavigateModal';
import IMyCounseling from '../../type/IMyCounselingData';
import useUserData from '../../hooks/useUserData';

interface IMyCounselingsType {
  reservedCounseling: IMyCounseling[];
  completedCounseling: IMyCounseling[];
}

function PersonalCounseling() {
  const navigate = useNavigate();

  const location = useLocation();
  const [tab, setTab] = useState(Number(new URL(document.URL).searchParams.get('tab')) || 0);
  const queryClient = useQueryClient();
  useEffect(() => {
    setTab(Number(new URL(document.URL).searchParams.get('tab')) || 0);
  }, [location]);

  function handleRefresh() {
    if (tab === 0) queryClient.invalidateQueries(['counselorList']);
  }

  const [navigateModalOpen, setNavigateModalOpen] = useState(false);

  const userData = useUserData();
  const { data } = useQuery<IMyCounselingsType>(
    ['myCounseling'],
    () => axiosAuthInstance.get(`/api/counselling/my?userId=${userData.data?.userId}`).then((res) => res.data),
    {
      staleTime: 1000 * 60 * 5,
    }
  );

  function checkTodaySchedule() {
    if (!data) return;
    const [year, month, date] = (() => {
      const today = new Date();
      return [today.getFullYear(), today.getMonth() + 1, today.getDate()];
    })();
    for (let i = 0; i < data.reservedCounseling.length; i++) {
      const [counselingYear, counselingMonth, counselingDate] = data.reservedCounseling[i].counselingDate
        .split('-')
        .map((str) => Number(str));
      if (year === counselingYear && month === counselingMonth && date === counselingDate) {
        return true;
      }
    }
    return false;
  }
  return (
    <>
      <main className={styles.container}>
        <div className={styles.bg}>
          <Header isNavShow />
          <div className={styles.contentWrap}>
            <div className={styles.mobileHeader}>
              <button className={styles.backBtn} onClick={() => navigate('/health/mind-therapy')}>
                <MetaSpaceIcon />
              </button>
              <div className={styles.center} onClick={() => setNavigateModalOpen(true)}>
                <span>1:1 상담</span>
                <Open
                  style={{
                    transform: `rotate(${navigateModalOpen ? '180deg' : '0'})`,
                  }}
                />
              </div>
              <button className={styles.refresh} onClick={handleRefresh}>
                <Refresh />
              </button>
            </div>
            <div className={styles.menu}>
              <button className={styles.hit}>1:1 상담</button>
              <button onClick={() => navigate('/mind-therapy/inner-healing/check', { replace: true })}>이너힐링</button>
            </div>
            <div className={styles.scrollableArea}>
              <ul className={styles.tabs}>
                <li onClick={() => navigate('?tab=0', { replace: true })} className={tab === 0 ? styles.hit : ''}>
                  <span>상담 신청</span>
                </li>
                <li
                  onClick={() => navigate('?tab=1', { replace: true })}
                  className={`${tab === 1 ? styles.hit : ''} ${checkTodaySchedule() && styles.on}`}
                >
                  <span>내 상담</span>
                </li>
              </ul>
              {tab === 0 && <CounselorList />}
              {tab === 1 && <MyCounseling />}
            </div>
          </div>
        </div>
      </main>
      {navigateModalOpen && <LockerRoomNavigateModal isMindTherapy setNavigateModalOpen={setNavigateModalOpen} />}
    </>
  );
}
export default PersonalCounseling;

function CounselorList() {
  const navigate = useNavigate();
  const { inView, div } = useCostomInView();
  const userData = useUserData();

  const {
    data: availableCounselors,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<{
    content: ICounselorListType[];
    last: boolean;
  }>(['counselorList'], ({ pageParam = 0 }) => getCounselorData(pageParam), {
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.last) return allPages.length;
      else return false;
    },
    staleTime: 1000 * 60,
  });
  useEffect(() => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, availableCounselors]);

  const getCounselorData = async (page: number) => {
    let res = await axiosAuthInstance.get(
      `/api/counselling/counsellor/available?userId=${userData.data?.userId}&size=10&page=${page}`
    );
    return res.data;
  };

  return (
    <>
      {availableCounselors?.pages.length === 0 ? (
        <div className={styles.noResult}>
          <div>
            <p>아직 신청 가능한 상담이 없어요</p>
            <div className={styles.banner}></div>
          </div>
        </div>
      ) : (
        <div className={styles.counselingRequestWrap}>
          <ul className={styles.counselingList}>
            {availableCounselors?.pages.map((page) =>
              page.content.map((counselor) => (
                <li key={counselor.counselorId} className={styles.counseling}>
                  <div className={styles.top}>
                    <div className={styles.counselorInfo}>
                      <div className={styles.avatarWrap}>
                        <img src={counselor.profileImage || counselorDefault} alt={counselor.counselorName} />
                      </div>
                      <span>{counselor.counselorName} 상담사</span>
                    </div>
                    <button
                      className={counselor.available || true ? styles.hit : ''}
                      onClick={() => {
                        // if (counselor.available)
                        navigate(`/mind-therapy/personal-counseling/counselor/${counselor.counselorId}`);
                      }}
                    >
                      상담 신청하기
                    </button>
                  </div>
                  <p
                    className={styles.comment}
                    dangerouslySetInnerHTML={{
                      __html: counselor.introduction || '',
                    }}
                  ></p>
                  {counselor.keyword && (
                    <ul className={styles.tags}>
                      {counselor.keyword.split(',').map((tag) => (
                        <li key={tag}>{tag.replace(/#/g, '')}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))
            )}
            {!isFetching && div}
          </ul>
        </div>
      )}
      {isFetching && <Loading />}
    </>
  );
}

function MyCounseling() {
  const userData = useUserData();
  const { data, isLoading } = useQuery<IMyCounselingsType>(['myCounseling'], () =>
    axiosAuthInstance.get(`/api/counselling/my?userId=${userData.data?.userId}`).then((res) => {
      const result: IMyCounselingsType = res.data;
      result.reservedCounseling.sort(
        (a, b) =>
          new Date(`${a.counselingDate} ${a.counselingTime}`).getTime() -
          new Date(`${b.counselingDate} ${b.counselingTime}`).getTime()
      );
      result.reservedCounseling = result.reservedCounseling.filter((counseling) => {
        const IS_PAST =
          new Date().getTime() >=
          new Date(`${counseling.counselingDate} ${counseling.counselingTime}`).getTime() + 1000 * 60 * 60;
        if (IS_PAST) {
          result.completedCounseling.unshift(counseling);
          return false;
        } else return true;
      });

      result.completedCounseling.sort(
        (a, b) =>
          new Date(`${b.counselingDate} ${b.counselingTime}`).getTime() -
          new Date(`${a.counselingDate} ${a.counselingTime}`).getTime()
      );
      return result;
    })
  );
  return (
    <>
      {data?.reservedCounseling.length === 0 && data.completedCounseling.length === 0 ? (
        <div className={`${styles.noResult} ${styles.mine}`}>
          <div>
            <p>신청한 상담이 없어요</p>
            <div className={styles.banner}></div>
          </div>
        </div>
      ) : (
        <div className={styles.myCounselingWrap}>
          {!!data?.reservedCounseling.length && (
            <section className={styles.yetCounseling}>
              <div className={styles.sectionHeader}>
                <h4>예정된 상담</h4>
                <p>* 상담 시작 5분 전부터 입장할 수 있어요.</p>
              </div>
              <ul className={styles.myCounselingList}>
                {data?.reservedCounseling.map((counseling, idx) => (
                  <li key={idx}>
                    <Counseling counseling={counseling} />
                  </li>
                ))}
              </ul>
            </section>
          )}
          {!!data?.completedCounseling.length && (
            <section className={styles.pastCounseling}>
              <div className={styles.sectionHeader}>
                <h4>지난 상담</h4>
              </div>
              <ul className={styles.myCounselingList}>
                {data?.completedCounseling.map((counseling, idx) => (
                  <li key={idx}>
                    <Counseling counseling={counseling} isPastCounseling />
                  </li>
                ))}
              </ul>
            </section>
          )}
        </div>
      )}
      {isLoading && <Loading />}
    </>
  );
}
