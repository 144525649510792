import { useEffect, useRef, useState } from 'react';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import styles from './PhysicalInfo.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PHYSICAL_REGEX from '../../constant/physicalRegex';
import CustomConfirm from '../../components/CustomConfirm/CustomConfirm';
import Header from '../../components/Header/Header';
import { useMutation } from 'react-query';
import { axiosInstance } from '../../api/axios';
import Loading from '../../components/Loading/Loading';
import forceVertical from '../../common/forceVertical';
import useUserData from '../../hooks/useUserData';

function PhysicalInfo() {
  const { state } = useLocation();
  const { category } = useParams() as { category: 'msk' | 'metabolism' };
  const userData = useUserData();

  const navigate = useNavigate();
  const location = useLocation();

  const [stopModalOpen, setStopModalOpen] = useState(false);

  const [value, setValue] = useState([sessionStorage.getItem('height') || '', sessionStorage.getItem('weight') || '']);

  useEffect(() => {
    if (category !== 'msk' && category !== 'metabolism') {
      alert('잘못된 경로로 접속하였습니다.');
      navigate('/fitness/exercise-of-the-day');
    }
  }, [location]);

  // index가 0이면 키를 입력받고 1이면 몸무게를 입력받음
  const [index, setIndex] = useState(Number(new URL(window.location.href).searchParams.get('index') || '0'));

  const [info, setInfo] = useState(index === 0 ? '키' : index === 1 ? '몸무게' : '');

  useEffect(() => {
    let newIndex = Number(new URL(window.location.href).searchParams.get('index') || 0);
    if ((newIndex !== 0 && newIndex !== 1) || (newIndex === 1 && !value[0])) {
      navigate('?index=0');
      return;
    }
    setIndex(newIndex);
    setInfo(newIndex === 0 ? '키' : newIndex === 1 ? '몸무게' : '');
  }, [location]);

  // //모바일 input focus시 하단 버튼 키보드 위로 올리기 위한 코드
  const fixedBtnRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!window.visualViewport) return;
    const vv = window.visualViewport;
    function fixPosition() {
      if (!fixedBtnRef.current) return;
      fixedBtnRef.current.style.top = `${forceVertical() ? vv.width : vv.height}px`;
    }
    vv.addEventListener('resize', fixPosition);
    fixPosition(); // Make sure we call it once before resizing too
    return () => {
      vv.removeEventListener('resize', fixPosition);
    };
  }, []);
  //모바일 input focus시 하단 버튼 키보드 위로 올리기 위한 코드

  const validationCheck = () => {
    const check = value[index];
    if (check && check[check.length - 1] !== '.') return true;
    else return false;
  };

  const { mutateAsync, isLoading } = useMutation(() =>
    axiosInstance.post('/api/fitness/user-info/body/measure', {
      userId: Number(userData.data?.userId),
      height: Number(value[0]),
      weight: Number(value[1]),
    })
  );

  function handlePrev() {
    if (index > 0) navigate(`?index=${index - 1}`);
  }
  async function handleNext() {
    if (index === 0 && validationCheck()) {
      sessionStorage.setItem('height', value[0]);
      navigate('?index=1', { state });
    } else if (index === 1 && validationCheck()) {
      sessionStorage.setItem('weight', value[1]);
      await mutateAsync();
      console.log(category);
      navigate(`/physical-check/${category}?index=${category === 'msk' ? 0 : 1}`, { state });
    }
  }
  return (
    <>
      <main className={styles.container}>
        <div className={styles.bg}>
          <Header isNavShow />
          <div className={styles.contentWrap}>
            <MobileHeader title={`${info} 입력`} backBtn='arrow' backFn={() => setStopModalOpen(true)} />
            <div className={styles.menu}>
              <button>오늘의 운동</button>
            </div>
            <div className={styles.scrollableArea}>
              <div className={styles.tab}>검사</div>
              <form
                className={styles.physicalInfoForm}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNext();
                }}
              >
                <p>{info}를 입력해주세요.</p>
                <div className={styles.inputWrap}>
                  <input
                    type='text'
                    placeholder={`${info === '키' ? '0' : ''}00.0`}
                    value={value[index]}
                    onChange={(e) => {
                      if (!PHYSICAL_REGEX.test(e.currentTarget.value)) return;
                      const VAL = e.currentTarget.value;
                      setValue((prev) => {
                        let newVal = [...prev];
                        newVal[index] = VAL;
                        return newVal;
                      });
                    }}
                  />
                  <span className={styles.unit}>{info === '키' ? 'cm' : 'kg'}</span>
                  <div className={styles.roundBorder}></div>
                </div>
              </form>
              <div className={styles.submitBtnWrap} ref={fixedBtnRef}>
                <button className={`${styles.prev} ${index > 0 ? styles.hit : ''}`} onClick={handlePrev}>
                  이전
                </button>
                <button className={`${styles.next} ${validationCheck() ? styles.hit : ''}`} onClick={handleNext}>
                  다음
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      {stopModalOpen && (
        <CustomConfirm
          message='검사를 그만할까요?'
          falseText='아니요'
          trueText='예, 그만할게요'
          falseFn={() => setStopModalOpen(false)}
          trueFn={() => navigate('/fitness/exercise-of-the-day')}
        />
      )}
      {isLoading && <Loading />}
    </>
  );
}
export default PhysicalInfo;
